import { useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { useTenantContext } from '~/context/TenantProvider';
import { ThemeCtx } from '~/context/ThemeProvider/ThemeProvider';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import { customLogosByVideoSlugsMap } from '~/pages/SingleVideoPage/const';
import { DiscoverRoutes } from '~/routes';
import { LearnerInfo } from '~/types';
import { getHeaderLogoSsku, TenantsRefs } from '~/utils/tenantsConfig';

const useCompanyLogo = (userInfo?: LearnerInfo) => {
  const { tenant } = useTenantContext();
  const { mode } = useContext(ThemeCtx);
  const isSsku = useIsSsku();
  const [url, setUrl] = useState(tenant?.logo_url);
  const location = useLocation();
  const isVideoPage = location.pathname?.startsWith(DiscoverRoutes.Videos);

  let initialLogo = isSsku
    ? getHeaderLogoSsku(mode)
    : userInfo?.company?.logo_url || tenant?.logo_url;

  if (
    process.env.REACT_APP_BUILD_TYPE === 'prod'
      ? !userInfo && tenant?.name === TenantsRefs.Su && isVideoPage
      : !userInfo && isVideoPage
  ) {
    const slug = location.pathname.replace(DiscoverRoutes.Videos, '').replaceAll('/', '');
    const customLogo = customLogosByVideoSlugsMap[slug];
    if (customLogo) {
      initialLogo = customLogo;
    }
  }

  useEffect(() => {
    setUrl(initialLogo);
  }, [initialLogo]);

  return { url, update: setUrl };
};

export default useCompanyLogo;
