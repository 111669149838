import React, { useContext } from 'react';

import { useLocation, Navigate } from 'react-router-dom';

import { UserInfoCtx } from '../containers/CommonLayout/CommonLayout';
import { RXDContext } from '../context/RXDContext';
import useAuth from '../hooks/useAuth';
import Cookies from '../utils/cookies';

const RequireRXDAuth = ({ children }) => {
  const lms_token = Cookies.get('lms_token');
  const { rxdUserInfo } = useContext(RXDContext);
  const location = useLocation();
  const { isInitialized, isLoading } = useAuth();
  const { userInfo, userLoading, hasAccessToContent } = useContext(UserInfoCtx);

  if (userLoading || !isInitialized || isLoading) {
    return null;
  } else if (!(!userLoading && hasAccessToContent) && (!lms_token || !rxdUserInfo?.id)) {
    if (window?.frameElement) {
      return <Navigate to="/rxd/landing" replace />;
    } else if (!userInfo) {
      return <Navigate to="/login" state={{ prevPath: location.pathname }} replace />;
    } else if (!hasAccessToContent) {
      return <Navigate to="/pricing" replace />;
    }
  }

  return children;
};

export default RequireRXDAuth;
