import { useCallback, useRef } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

const useSearchParams = <K extends string, V extends string>() => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParamsRef = useRef(new URLSearchParams(location?.search));
  queryParamsRef.current = new URLSearchParams(location?.search);

  const set = useCallback(
    (key: K, value: V) => {
      queryParamsRef.current.set(key, value);
      const stringifiedParams = queryParamsRef.current.toString();
      navigate(stringifiedParams ? `?${stringifiedParams}` : '');
    },
    [queryParamsRef, navigate],
  );

  const get = useCallback((key: K) => queryParamsRef.current.get(key), [queryParamsRef]);

  return { set, get };
};

export default useSearchParams;
