import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import useSearchParams from '~/hooks/useSearchParams';
import Cookies from '~/utils/cookies';

const utmParameterNames = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

export const getUtmParameters = () =>
  Object.fromEntries(utmParameterNames.map((name) => [name, Cookies.get(name)]));

const useTrackUtmParameters = () => {
  const location = useLocation();
  const { get } = useSearchParams();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      Cookies.removeEach(utmParameterNames);
    }

    utmParameterNames.forEach((name) => {
      const value = get(name);

      if (value) {
        Cookies.set(name, value);
      }
    });

    setIsInitialized(true);
  }, [location, get, isInitialized]);
};

export default useTrackUtmParameters;
