export const PAYWALLED_CONTENT_SELECTORS = {
  OVERVIEW: 'overview',
  SUMMARY: 'summary',
  VIDEO_PLAY_BTN: 'video-play-btn',
  VIDEO_PLAY_BTN_SMALL: 'video-play-btn-small',
  JOIN_US: 'join-us',
};

export const VIDEO_PAGE_CUSTOM_BLOCKS_URL = '/videos/';

export enum TabsEnum {
  Overview = 'overview',
  Summary = 'summary',
  Expert = 'expert',
  Reviews = 'reviews',
}

export const VIDEO_PAGE_EVENTS = {
  follow: 'Video_pg_Follow',
  thumbUp: 'Video_pg_ThumbUp',
  thumbDown: 'Video_pg_ThumbDown',
  comment: 'Video_pg_Comment',
  share: 'Video_pg_Share',
  shareLinkedIn: 'Video_pg_Share_LI',
  shareFacebook: 'Video_pg_Share_Fbook',
  shareMicrosoftTeams: 'Video_pg_Share_teams',
  shareTwitter: 'Video_pg_Share_X',
  addToWatchlist: 'Video_pg_Add_Wlist',
  nextVideo: 'Video_pg_Continue',
  selectAnswer: 'Video_post_KC_select',
  submitPostVideoAnswer: 'Video_post_KC_submit',
  questionExplainer: 'Video_post_KC_explainer',
  report: 'Video_post_KC_Flag',
  reportSubmit: 'Video_post_KC_Flag_submit',
  replay: 'Video_post_Replay',
  continuePostVideo: 'Video_post_Continue',
};

const danskeLogo = '/logos/Danske_newLogo.webp';
const customizedVideoSlugs =
  process.env.REACT_APP_BUILD_TYPE === 'prod'
    ? [
        'responsible-investment-approach',
        'inclusion',
        'active-ownership',
        'exclusions',
        'enhanced-sustainability-standards-screening',
        'responsible-investment-framework',
        'sfdr-implementation-in-danske-bank',
        'sustainability-commitments',
        'mdash',
      ]
    : ['uk-wholesale-market-regulation-post-brexit'];

export const customLogosByVideoSlugsMap = {
  ...customizedVideoSlugs.reduce(
    (acc, id) => ({
      ...acc,
      [id]: danskeLogo,
    }),
    {},
  ),
};

export const hasCustomLogoByVideoSlug = (slug: string = '') => customizedVideoSlugs.includes(slug);
