import React from 'react';

import { Helmet } from 'react-helmet';

import { useSsrContext } from '~/context/SsrContext';

const JSBlock = ({ data }) => {
  const jsCode = data?.js_code;
  const type = data?.customScriptType || 'application/javascript';
  const { nonce } = useSsrContext();

  return (
    <Helmet>
      <script type={type} nonce={nonce || window.__NONCE__}>
        {jsCode}
      </script>
    </Helmet>
  );
};

export default JSBlock;
