import React, { lazy, Suspense } from 'react';

import { Container } from '@mui/material';
import { captureException, setTag } from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';

import AreasBlock from '../../components/AreasBlock/AreasBlock';
import { CardsBlock } from '../../components/CardsBlock';
import { ContactDetailsBlock } from '../../components/ContactDetailsBlock';
import { ContactFormBlock } from '../../components/ContactFormBlock';
import ContentListBlock from '../../components/ContentListBlock/ContentListBlock';
import { CtaBlock } from '../../components/CtaBlock';
import { ExpertsBlock } from '../../components/ExpertsBlock';
import { HTMLBlock } from '../../components/HTMLBlock';
import HalfWidthImgBlock from '../../components/HalfWidthImgBlock';
import { ImageHeadingTextBlock } from '../../components/ImageHeadingTextBlock';
import ImageTextArrayBlock from '../../components/ImageTextArrayBlock/ImageTextArrayBlock';
import ImageTextArrayBlockSized from '../../components/ImageTextArrayBlock/ImageTextArrayBlockSized';
import ImageWithTabsBlock from '../../components/ImageWithTabsBlock';
import { IntroBlock } from '../../components/IntroBlock';
import { JSBlock } from '../../components/JSBlock';
import MeetTheTeam from '../../components/MeetTheTeam/MeetTheTeam';
import NewsBlock from '../../components/NewsBlock/NewsBlock';
import { PartnersBlock } from '../../components/PartnersBlock';
import { PathwaysListBlock } from '../../components/PathwaysListBlock';
import { QuotesBlock } from '../../components/QuotesBlock';
import { SeoBlock } from '../../components/SeoBlock';
import TabsSliderBlock from '../../components/TabsSliderBlock/TabsSliderBlock';
import { TrustpilotBlock } from '../../components/TrustpilotBlock';
import VideosBlock from '../../components/VideosBlock/VideosBlock';
import { WeAreBlock } from '../../components/WeAreBlock';
import WhyUsWideImgBlock from '../../components/WhyUsWideImgBlock';
import useAnchor from '../../hooks/useAnchor';
import useAuth from '../../hooks/useAuth';
import useQueriesInProgress from '../../hooks/useQueriesInProgress';
import styled from '../../styled';
import { Block, IntroImageBlockViewType, LearnerInfo, Tenant } from '../../types';
import CustomBlockError from '../../utils/errors/CustomBlockError';
import AccordionsBlock from '../AccordionsBlock/AccordionsBlock';
import CoursesBlock from '../CoursesBlock/CoursesBlock';
import ImageAndTextBlock from '../ImageAndTextBlock/ImageAndTextBlock';
import LetsTalkBlock from '../LetsTalkBlock/LetsTalkBlock';
import RequestADemo from '../RequestADemo/RequestADemo';
import RequestAFullCourseListBlock from '../RequestAFullCourseListBlock/RequestAFullCourseListBlock';
import Testimonials from '../Testimonials/Testimonials';

const SearchBlock = lazy(() => import('../SearchBlock/SearchBlock'));
const PricePage = lazy(() => import('../../pages/PricePage/PricePage'));

const BLOCKS = [
  {
    type: 'intro-video-block',
    Component: IntroBlock,
  },
  {
    type: 'intro-image-block',
    view_type: IntroImageBlockViewType.Intro,
    Component: (data) => <IntroBlock {...data} video={false} />,
  },
  {
    type_image: 'fixed_image',
    Component: ImageHeadingTextBlock,
  },
  {
    type_image: 'wide_image',
    Component: WhyUsWideImgBlock,
  },
  {
    type: 'images-and-text-array-block',
    Component: (props) =>
      props.data.type_block === 'sized' ? (
        <ImageTextArrayBlockSized {...props} />
      ) : (
        <ImageTextArrayBlock {...props} />
      ),
  },
  {
    type: 'partners',
    Component: PartnersBlock,
  },
  {
    type: 'newslayout',
    Component: NewsBlock,
  },
  {
    type: 'videolist',
    Component: VideosBlock,
  },
  {
    type: 'pathwaylist',
    Component: PathwaysListBlock,
  },
  {
    type: 'catalogue-of-categories',
    Component: AreasBlock,
  },
  {
    type: 'experts',
    Component: ExpertsBlock,
  },
  {
    type: 'contact-details-block',
    Component: ContactDetailsBlock,
  },
  {
    type: 'contact-form-block',
    Component: ContactFormBlock,
  },
  {
    type: 'seo-block',
    Component: SeoBlock,
  },
  {
    type: 'pricingblock',
    Component: (props) => (
      <Suspense>
        <PricePage {...props} isBlock={true} />
      </Suspense>
    ),
  },
  {
    type: 'html-block',
    Component: HTMLBlock,
  },
  {
    type: 'js-block',
    Component: JSBlock,
  },
  {
    type: 'quotes-list-block',
    Component: QuotesBlock,
  },
  {
    type: 'cta-block',
    Component: CtaBlock,
  },
  {
    type: 'for-enterprise',
    Component: ImageWithTabsBlock,
  },
  {
    type: 'all-professionals',
    Component: HalfWidthImgBlock,
  },
  {
    type: 'trustpilot-block',
    Component: TrustpilotBlock,
  },
  {
    type: 'image-slider-with-text',
    Component: TabsSliderBlock,
  },
  {
    type: 'what-businesses-stand-to-gain',
    Component: CardsBlock,
  },
  {
    type: 'meet-the-team',
    Component: MeetTheTeam,
  },
  {
    type: 'we-are-block',
    Component: WeAreBlock,
  },
  {
    type: 'testimonials',
    Component: Testimonials,
  },
  {
    type: 'faq',
    Component: AccordionsBlock,
  },
  {
    type: 'request_demo',
    Component: RequestADemo,
  },
  {
    type: 'intro-image-block',
    view_type: IntroImageBlockViewType.Inline,
    Component: ImageAndTextBlock,
  },
  {
    type: 'courselist',
    Component: CoursesBlock,
  },
  {
    type: 'search_block',
    Component: (props) => (
      <Suspense>
        <SearchBlock {...props} />
      </Suspense>
    ),
  },
  {
    type: 'content-item-list',
    Component: ContentListBlock,
  },
  {
    type: 'let-us-talk',
    Component: LetsTalkBlock,
  },
  {
    type: 'request-course-list',
    Component: RequestAFullCourseListBlock,
  },
];

interface CustomBlocksProps {
  blocks: (Block | null)[] | null;
  tenant?: Tenant | null;
  userInfo?: LearnerInfo;
  preventLoader?: boolean;
  blocksLoading?: boolean;
  canonicalUrl?: string;
}

const CustomBlocks: React.FC<CustomBlocksProps> = ({
  blocks,
  preventLoader,
  tenant,
  userInfo,
  blocksLoading,
  canonicalUrl,
}) => {
  const { isInitialized } = useAuth();
  const blocksNotLoaded = !isInitialized || blocksLoading;

  let blocksArray: any[] = [];

  if (blocks) {
    // eslint-disable-next-line
    for (let [key, value] of Object.entries(blocks)) {
      blocksArray.push({ ...value });
    }
  }

  // For "images-and-text-array-block" we need to check their ranking
  // to show them differently if they go one after each other
  let counter = 0;
  let startFromImage = false;

  const { inProgress } = useQueriesInProgress();
  useAnchor(null, { skip: inProgress, delay: 0 });

  blocksArray.forEach((block) => {
    if (block?.type === 'images-and-text-array-block') {
      if (counter === 0 && block?.items?.length && block.items[0]?.type === 'image') {
        startFromImage = true;
      }
      counter += 1;
      block.shouldStartFromImage = startFromImage ? Boolean(counter % 2) : !(counter % 2);
    } else {
      counter = 0;
      startFromImage = false;
    }
  });

  return (
    <StyledContainer blocksNotLoaded={blocksNotLoaded} maxWidth={false}>
      {blocksArray.map((block, index) => {
        const desiredBlock = BLOCKS.find((item) =>
          block.type_image
            ? item.type_image === block.type_image
            : item.type === block.type &&
              (item?.view_type ? block?.view_type === item?.view_type : true),
        );
        const Component = desiredBlock?.Component;

        if (!Component) return null;

        return (
          <ErrorBoundary
            key={index}
            fallback={<div />}
            onError={(error) => {
              setTag('block_type', block?.type || block?.type_image);
              captureException(new CustomBlockError(error?.message));
            }}
          >
            <Component
              data={block}
              tenant={tenant}
              userInfo={userInfo}
              preventLoader={preventLoader}
              canonicalUrl={canonicalUrl}
            />
          </ErrorBoundary>
        );
      })}
    </StyledContainer>
  );
};

export default CustomBlocks;

const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'blocksNotLoaded',
})`
  flex: 1;
  flex-grow: 1;
  overflow: hidden;
  min-height: ${({ blocksNotLoaded }) => (blocksNotLoaded ? '100vh' : 'unset')};

  && {
    padding: 0;
  }
`;
