import { captureException } from '@sentry/react';
import { formatISO } from 'date-fns';
import md5 from 'md5';

import { USER_ROLES } from '../pages/const';
import { LearnerInfo, SubscriptionGroupType } from '../types';
import UserCom, { UserComEvents } from './UserCom';
import { sendEventToGTM } from './gtmUtils';
import sha256 from './sha256';

type UpdateUserComPlanIntoType = {
  plan?: string | null;
  planType?: SubscriptionGroupType | null;
  renewDate?: string | null;
  trialLeft?: number | null;
  userInfo?: LearnerInfo | null;
};

export const updateUserComPlanInfo = ({
  plan,
  planType,
  renewDate,
  trialLeft = undefined,
  userInfo = undefined,
}: UpdateUserComPlanIntoType) => {
  if (UserCom.checkHasUserCom()) {
    let renewOn;
    if (renewDate) {
      try {
        renewOn = Date.parse(renewDate);
      } catch (e) {}
    }

    UserCom.updateClient({
      ...(plan && { current_plan: plan }),
      ...(planType && { plan_type: planType }),
      has_subscription: !!userInfo?.has_subscription,
      had_subscription_bool: Boolean(userInfo?.had_subscription),
      ...(renewOn && {
        sub_end_date: formatISO(renewOn, { representation: 'date' }),
      }),
      ...(userInfo?.role && { role: getRoleForUserCom(userInfo?.role) }),
      is_on_trial: Boolean(trialLeft),
      ...(userInfo?.teams?.length && { team_id: userInfo?.teams?.[0]?.id }),
      ...(userInfo?.teams?.length && { team_name: userInfo?.teams?.[0]?.name }),
      ...(userInfo?.chargebee_tenant_name && { tenant_name: userInfo?.chargebee_tenant_name }),
      ...(userInfo?.company?.name && { company_name: userInfo.company.name }),
    });
  }
};

export const getRoleForUserCom = (role: LearnerInfo['role'] | null | undefined) => {
  switch (role) {
    case USER_ROLES.CompanyOwner:
      return 'Company Owner';
    case USER_ROLES.CompanyManager:
      return 'Account Manager';
    case USER_ROLES.TeamManager:
      return 'Manager';
    case USER_ROLES.B2BLearner:
      return 'B2B learner';
    case USER_ROLES.B2CLearner:
      return 'B2C learner';
    default:
      return null;
  }
};

const passUserComEventWithHashedEmailToGtm = (data) => {
  if (data?.dataLayerVariables?.formEmail) {
    sha256(data?.dataLayerVariables?.formEmail)
      .then((hashedEmail) => {
        sendEventToGTM(data?.value, {
          ...data?.dataLayerVariables,
          formEmail: hashedEmail,
        });
      })
      .catch((e) => {
        captureException(e);
      });
  } else {
    sendEventToGTM(
      data?.value,
      typeof data?.dataLayerVariables === 'object' ? data?.dataLayerVariables : undefined,
    );
  }
};

//used to send custom GTM event on user.com form submission
export const handleUserComPayload = (passToGtm: boolean) => (message: string) => {
  try {
    const data = JSON.parse(message);
    if (data?.type === UserComEvents.gtmEvent && data?.value === 'subscription-purchased') {
      if (passToGtm) {
        passUserComEventWithHashedEmailToGtm(data);
      }
    } else if (data?.type === UserComEvents.gtmEvent && data?.value) {
      passUserComEventWithHashedEmailToGtm(data);
    }
  } catch (e) {
    captureException(e);
  }
};

export const getUserComUserId = (id?: string | null): string =>
  process.env.REACT_APP_BUILD_TYPE === 'prod'
    ? md5(id)
    : `${md5(id)}_${process.env.REACT_APP_BUILD_TYPE}`;

export const splitName = (name: string) => {
  const nameArr = name.split(' ');
  const firstName = nameArr[0];
  const lastName = nameArr.length > 1 ? nameArr.slice(1).join(' ') : '';

  return [firstName, lastName];
};
