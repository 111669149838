import React, { useContext, useState } from 'react';

import { useQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { captureException } from '@sentry/react';

import { getUtmParameters } from '~/hooks/useTrackUtmParameters';

import Checkbox from '../../components/Checkbox/Checkbox';
import { CustomButton } from '../../components/UI/CustomButton';
import { BodyText } from '../../components/UI/Texts';
import { UserInfoCtx } from '../../containers/CommonLayout/CommonLayout';
import { useAlert } from '../../context/AlertProvider';
import { TenantCtx } from '../../context/TenantProvider';
import { GET_LIST_BASE_WITH_SORT } from '../../graphql/analytics';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import useValidation from '../../hooks/useValidation';
import { BIG_MARGIN_PX, MIDDLE_MARGIN_PX } from '../../theme';
import { Query, QueryListBaseVideoCategoriesArgs, SortOrder } from '../../types';
import UserCom from '../../utils/UserCom';
import { checkIsSSR } from '../../utils/checkIsSSR';
import { GtmEvents, sendEventToGTM } from '../../utils/gtmUtils';
import replaceByRegExp from '../../utils/stringManipulations/replaceByRegExp';
import { splitName } from '../../utils/userComUtils';
import ExtendedFormFields from './ExtendedFormFields';
import FormFields from './FormFields';
import { letsTalkExtendedValidation, letsTalkValidation } from './validation';

const requestVariables = {
  sort: [
    {
      column: 'title',
      order: SortOrder.Asc,
    },
  ],
};

const initialValues = {
  name: '',
  email: '',
  company: '',
  phone: '',
  newsletterPositiveConsent: true,
};

const initialValuesExtended = {
  ...initialValues,
  jobTitle: '',
  country: '',
  categoriesInterested: [],
};

const Form = ({
  buttonText,
  bookTimeLink,
  bookTimeLinkTitle,
  isFormExtended,
  usercomFormId,
  formTitleAutomation = '',
}) => {
  const [loading, setLoading] = useState(false);
  const isSSR = checkIsSSR();
  const { showAlert } = useAlert();
  const { userInfo } = useContext(UserInfoCtx);
  const { tenant } = useContext(TenantCtx);
  const { t } = useTenantTranslation();

  const {
    values,
    errors,
    handleChange,
    setFieldManually,
    handleAutocompleteChange,
    handleSubmit,
    resetToDefault,
  } = useValidation(
    isFormExtended ? initialValuesExtended : initialValues,
    isFormExtended ? letsTalkExtendedValidation : letsTalkValidation,
    onSubmit,
  );

  async function onSubmit(values) {
    try {
      setLoading(true);

      const [firstName, lastName] = splitName(values.name);
      const data = {
        email: values.email,
        company_name: values.company,
        ...(values?.phone && { phone_number: values.phone }),
        ...(isFormExtended && {
          job_title: values.jobTitle,
          country: values.country,
          ...(values?.categoriesInterested?.length && {
            categories_interested: values.categoriesInterested.map((category) => category?.label),
          }),
        }),
        ...(!userInfo && { newsletter_positive_consent: values.newsletterPositiveConsent }),
        request_form_title_automation: formTitleAutomation || "Let's talk",
        form_tenant: tenant?.name || '',
        ...getUtmParameters(),
      };

      await UserCom.updateClient({
        first_name: firstName,
        last_name: lastName,
        ...data,
      });

      await UserCom.sendEvent('form_conversion', {
        form_id: usercomFormId,
        name: values.name,
        ...data,
      });

      sendEventToGTM(GtmEvents.submitForm, { email: data.email });
      showAlert({ message: t('alert.getInTouch') });
      resetToDefault();
      setLoading(false);
    } catch (e) {
      captureException(e);
    }
  }

  const { data: baseCategoriesData } = useQuery<
    Pick<Query, 'listBaseVideoCategories'>,
    QueryListBaseVideoCategoriesArgs
  >(GET_LIST_BASE_WITH_SORT, {
    variables: requestVariables,
    skip: isSSR || !isFormExtended,
  });

  const categoriesOptions =
    baseCategoriesData?.listBaseVideoCategories?.map((category) => ({
      value: category?.id,
      label: category?.title,
    })) || [];

  const replaceWithLink = replaceByRegExp(new RegExp(/\*\*(?:(?!\*\*).)+\*\*/g), (match) => (
    <StyledLink
      href={bookTimeLink}
      target="popup"
      onClick={() => window.open(bookTimeLink, 'popup', 'width=700,height=600')}
    >
      {match?.slice(2, -2)}
    </StyledLink>
  ));

  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
    >
      {isFormExtended ? (
        <ExtendedFormFields
          values={values}
          errors={errors}
          handleChange={handleChange}
          categoriesOptions={categoriesOptions}
          handleAutocompleteChange={handleAutocompleteChange}
        />
      ) : (
        <FormFields values={values} errors={errors} handleChange={handleChange} />
      )}
      {!userInfo && (
        <StyledCheckbox
          name="newsletterPositiveConsent"
          onChange={() =>
            setFieldManually('newsletterPositiveConsent', !values?.newsletterPositiveConsent)
          }
          checked={values?.newsletterPositiveConsent}
        >
          {t('block.letsTalk.newsletterAgreement')}
        </StyledCheckbox>
      )}
      <ButtonBlock>
        <StyledButton type="submit" disabled={loading}>
          {buttonText}
        </StyledButton>
        {Boolean(bookTimeLink && bookTimeLinkTitle) && (
          <BookText>{replaceWithLink(bookTimeLinkTitle)}</BookText>
        )}
      </ButtonBlock>
    </StyledForm>
  );
};

export default Form;

const StyledForm = styled('form')`
  margin-bottom: ${BIG_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: ${MIDDLE_MARGIN_PX};
`;

const ButtonBlock = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledButton = styled(CustomButton)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
  }
`;

const BookText = styled(BodyText)`
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: ${MIDDLE_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: ${MIDDLE_MARGIN_PX};
    margin-top: 0;
  }
`;

const StyledLink = styled('a')`
  color: ${({ theme }) => theme.palette.common.primary};
`;
